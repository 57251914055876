import React from 'react';
import { useTranslation } from 'react-i18next';

const HeroSection = () => {
  const { t } = useTranslation();

  return (
    <section className="hero hero-v2">
      <div className="hero__background hero__background--grid">
        <img className="hero__grid-bg" src="/assets/media/images/landing-page-v2/landing-page-v2-hero-grid-bg.png" alt={t('hero.background_grid')} />
      </div>
      <div className="hero__background hero__background--pattern">
        <img className="hero__pattern-bg" src="/assets/media/images/landing-page-v2/landing-page-v2-hero-pattern.png" alt={t('hero.background_pattern')} />
      </div>
      <div className="hero__background--mobile hero__background--grid">
        <img className="hero__grid-bg" src="/assets/media/images/landing-page-v2/mobile-landing-page-v2-hero-grid.png" alt={t('hero.background_grid_mobile')} />
      </div>
      <div className="hero__background--mobile hero__background--pattern">
        <img className="hero__pattern-bg" src="/assets/media/images/landing-page-v2/mobile-landing-page-v2-hero-pattern.png" alt={t('hero.background_pattern_mobile')} />
      </div>
      <div className="container">
        <div className="hero__container">
          <div className="hero__content" data-aos="fade-right">
            <h1 className="hero__title">{t('hero.title')}</h1>
            <p className="fb-lg hero__subtitle">{t('hero.subtitle')}</p>
            <a href="https://t.me/walletpayin" className="btn btn-primary btn-lg btn-pill hero__link">
              {t('hero.button')}
            </a>
          </div>
          <div className="hero__illustration" data-aos="zoom-in-up" data-aos-delay="100">
            <img src="/assets/media/images/landing-page-v2/landing-page-v2-hero-illustration.png" alt={t('hero.illustration')} />
          </div>
        </div>
      </div>
    </section>
  );
};

export default HeroSection;
