import React from 'react';
import { useTranslation } from 'react-i18next';

const MigrationBanner = () => {
  const { t } = useTranslation();

  const styles = {
    wrapper: {
      padding: '40px 20px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      background: 'linear-gradient(180deg, rgba(22, 27, 34, 0) 0%, rgba(22, 27, 34, 0.3) 50%, rgba(22, 27, 34, 0) 100%)',
    },
    migrationBanner: {
      background: 'rgba(22, 27, 34, 0.7)',
      backdropFilter: 'blur(10px)',
      borderRadius: '16px',
      padding: '24px',
      border: '1px solid rgba(59, 130, 246, 0.1)',
      maxWidth: '900px',
      width: '100%'
    },
    migrationContent: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      gap: '24px',
      marginBottom: '20px',
      '@media (max-width: 600px)': {
        flexDirection: 'column',
        gap: '16px'
      }
    },
    migrationBox: {
      background: 'rgba(255, 255, 255, 0.03)',
      borderRadius: '12px',
      padding: '16px 24px',
      textAlign: 'center',
      minWidth: '180px'
    },
    migrationLabel: {
      color: '#94a3b8',
      marginBottom: '8px',
      fontSize: '0.9rem',
      fontWeight: '500'
    },
    migrationValue: {
      color: '#e2e8f0',
      fontSize: '1.2rem',
      fontWeight: '600'
    },
    arrow: {
      color: '#3b82f6',
      fontSize: '24px',
      '@media (max-width: 600px)': {
        transform: 'rotate(90deg)'
      }
    },
    migrationNote: {
      textAlign: 'center',
      color: '#94a3b8',
      fontSize: '0.95rem',
      lineHeight: '1.6',
      margin: '0',
      marginBottom: '16px'
    },
    contractAddress: {
      textAlign: 'center',
      color: '#94a3b8',
      fontSize: '0.9rem',
      fontFamily: 'monospace',
      padding: '8px',
      background: 'rgba(255, 255, 255, 0.03)',
      borderRadius: '8px',
      marginTop: '8px',
      userSelect: 'text',
      marginBottom: '24px'
    },
    logoContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      gap: '32px',
      marginTop: '16px',
      flexWrap: 'wrap'
    },
    logoWrapper: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      transition: 'transform 0.2s ease',
      '&:hover': {
        transform: 'translateY(-2px)'
      }
    },
    logo: {
      height: '32px',
      width: 'auto',
      opacity: '0.8',
      transition: 'opacity 0.2s ease',
      filter: 'brightness(0) invert(1)',
      '&:hover': {
        opacity: '1'
      }
    }
  };

  return (
    <div style={styles.wrapper}>
      <div style={styles.migrationBanner} data-aos="fade-up">
        <div style={styles.migrationContent}>
          <div style={styles.migrationBox}>
            <div style={styles.migrationLabel}>{t('migrationBanner.oldTokenSupplyLabel')}</div>
            <div style={styles.migrationValue}>{t('migrationBanner.oldTokenSupplyValue')}</div>
          </div>
          <div style={styles.arrow}>➜</div>
          <div style={styles.migrationBox}>
            <div style={styles.migrationLabel}>{t('migrationBanner.newTokenSupplyLabel')}</div>
            <div style={styles.migrationValue}>{t('migrationBanner.newTokenSupplyValue')}</div>
          </div>
        </div>
        <p style={styles.migrationNote}>
          {t('migrationBanner.migrationNote')}
        </p>
        <div style={styles.contractAddress}>
          NEW CA: 0x54365941BBA95c833D129CA55981c0F08562392d
        </div>
        <div style={styles.logoContainer}>
          <a 
            href="https://www.dextools.io/app/en/ether/pair-explorer/0x28450F5b5576c19cc1FD90fA84EDcf893d136624?t=1729658005746"
            target="_blank"
            rel="noopener noreferrer"
            style={styles.logoWrapper}
          >
            <img
              src="https://cdn.prod.website-files.com/6597cc7be68d63ec0c8ce33f/65b652c77b3c487c2a45ab01_DEXTools__white.webp"
              alt="DEXTools"
              style={styles.logo}
            />
          </a>
          <a
            href="https://app.uniswap.org/explore/tokens/ethereum/0x28450F5b5576c19cc1FD90fA84EDcf893d136624"
            target="_blank"
            rel="noopener noreferrer"
            style={styles.logoWrapper}
          >
            <img
              src="https://upload.wikimedia.org/wikipedia/commons/thumb/5/5a/Uniswap_Logo_and_Wordmark.svg/1280px-Uniswap_Logo_and_Wordmark.svg.png"
              alt="Uniswap"
              style={styles.logo}
            />
          </a>
          <a
            href="https://etherscan.io/token/0x28450F5b5576c19cc1FD90fA84EDcf893d136624"
            target="_blank"
            rel="noopener noreferrer"
            style={styles.logoWrapper}
          >
            <img
              src="https://etherscan.io/assets/svg/logos/logo-etherscan.svg?v=0.0.2"
              alt="Etherscan"
              style={styles.logo}
            />
          </a>
        </div>
      </div>
    </div>
  );
};

export default MigrationBanner;